@import url(../fonts/lato/stylesheet.css);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

ul {
  list-style: none; }

a,
a:hover {
  text-decoration: none; }

input,
button,
textarea,
select {
  outline: none !important; }

body {
  background: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 16px;
  position: relative;
  -webkit-font-smoothing: antialiased; }

/* Fancybox */
.fancybox-thumbs__list,
.fancybox-button--thumbs {
  display: none; }

/* Title */
.title h2 {
  text-align: center;
  font-size: 36px;
  line-height: 31px;
  margin-bottom: 15px; }

/* Subtitle */
.subtitle {
  max-width: 555px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 18px; }

/* Overlay */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 15;
  display: none; }

/* Popup */
.popup {
  max-width: 470px;
  width: 100%;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 16;
  padding: 35px 60px;
  text-align: center;
  border-radius: 10px;
  display: none; }
  @media (max-width: 767.98px) {
    .popup {
      padding: 25px 30px; } }
  @media (max-width: 575.98px) {
    .popup {
      max-width: 350px; } }
  .popup__close {
    width: 55px;
    height: 55px;
    position: absolute;
    top: -25px;
    right: -25px;
    cursor: pointer; }
    @media (max-width: 767.98px) {
      .popup__close {
        width: 40px;
        height: 40px;
        top: -15px;
        right: -15px; } }
  .popup__title h2 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 18px; }
  .popup__text {
    font-size: 15px;
    line-height: 19px;
    font-weight: 400;
    margin: 0 auto 50px; }
    .popup__text span {
      display: block;
      font-weight: 700; }
  .popup-form {
    display: flex;
    flex-direction: column; }
    .popup-form input {
      width: 100%;
      border: 1px solid #fcdec6;
      border-radius: 5px;
      padding: 22px 20px 20px;
      font-size: 16px;
      color: #000; }
    .popup-form input::placeholder {
      font-size: 16px;
      color: #000; }
    .popup-form__name {
      margin-bottom: 17px; }
    .popup-form__phone {
      margin-bottom: 38px; }
      .popup-form__phone::-webkit-outer-spin-button, .popup-form__phone::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
    .popup-form__submit {
      width: 262px;
      border-radius: 5px;
      background-color: #842064;
      color: #fff;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      font-weight: 700;
      font-size: 17px;
      line-height: 15px;
      padding: 22px 38px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
      margin: 0 auto 20px;
      border: none;
      transition: all 0.5s ease; }
      .popup-form__submit:hover {
        background: #6d1852;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); }
  .popup__subtext {
    font-size: 12px;
    line-height: 14px; }
    .popup__subtext span {
      display: block;
      text-decoration: underline; }

.review-popup {
  max-width: 470px;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 16;
  padding: 35px 60px;
  text-align: center;
  border-radius: 10px;
  display: none; }
  .review-popup .popup__close {
    width: 55px;
    height: 55px;
    position: absolute;
    top: -25px;
    right: -25px;
    cursor: pointer; }
  .review-popup .popup__title h2 {
    margin-bottom: 25px;
    font-size: 35px;
    font-weight: 700; }
  .review-popup .popup__text {
    font-size: 15px;
    line-height: 19px;
    font-weight: 400;
    margin: 0 auto 45px; }
  .review-popup .popup-form__textarea {
    border: 1px solid #fcdec6;
    border-radius: 5px;
    min-height: 177px;
    width: 100%;
    resize: none;
    padding: 23px 19px;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    font-family: sans-serif;
    font: 500 16px "Lato";
    background-color: #fff;
    margin-bottom: 50px; }
    .review-popup .popup-form__textarea::placeholder {
      color: #000;
      font-family: sans-serif;
      font: 500 16px "Lato"; }
  .review-popup .popup-form {
    display: flex;
    flex-direction: column; }
    .review-popup .popup-form input {
      width: 100%;
      border: 1px solid #fcdec6;
      border-radius: 5px;
      padding: 22px 20px 20px;
      font-size: 16px;
      color: #000; }
    .review-popup .popup-form input::placeholder {
      font-size: 16px;
      color: #000; }
    .review-popup .popup-form__name {
      margin-bottom: 17px; }
    .review-popup .popup-form__phone {
      margin-bottom: 17px; }
      .review-popup .popup-form__phone::-webkit-outer-spin-button, .review-popup .popup-form__phone::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
    .review-popup .popup-form__submit {
      width: 262px;
      border-radius: 5px;
      background-color: #842064;
      color: #fff;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      font-weight: 700;
      font-size: 17px;
      line-height: 15px;
      padding: 22px 38px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
      margin: 0 auto 20px;
      border: none;
      transition: all 0.5s ease; }
      .review-popup .popup-form__submit:hover {
        background: #6d1852;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); }
  .review-popup .popup__subtext {
    font-size: 12px;
    line-height: 14px; }
    .review-popup .popup__subtext span {
      display: block;
      text-decoration: underline; }

/* Fixed Nav */
.moving-nav {
  display: none; }
  @media (max-width: 991.98px) {
    .moving-nav {
      display: block; } }

@media (max-width: 991.98px) {
  .moving-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 75px;
    height: 54px;
    background: #f9eee6;
    border-bottom-right-radius: 5px;
    padding-top: 13px;
    padding-left: 23px;
    z-index: 13;
    display: none; }
    .moving-nav__img {
      width: 26px;
      cursor: pointer; } }

.border-none {
  border-bottom: none !important; }

.p-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-20 {
  margin-bottom: 20px; }

.mb-35 {
  margin-bottom: 35px; }

.mb {
  margin-bottom: 44px; }

/* Container */
.container {
  max-width: 1170px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto; }

/* Header */
.header-wrapper {
  position: relative; }

.header-social {
  position: absolute;
  top: 0;
  right: 0;
  background: #f9eee6;
  border: 1px solid #e3ddd8;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding: 10px 30px 6px 40px; }
  @media (max-width: 991.98px) {
    .header-social {
      right: 50%;
      transform: translateX(50%); } }
  @media (max-width: 767.98px) {
    .header-social {
      justify-content: center; } }
  @media (max-width: 767.98px) {
    .header-social {
      max-width: 200px; } }
  @media (max-width: 767.98px) {
    .header-social {
      padding-left: 30px;
      border: none;
      background: none; } }
  .header-social__text {
    font-size: 14px;
    line-height: 17px; }
    @media (max-width: 767.98px) {
      .header-social__text {
        display: none; } }
  .header-social__items {
    display: flex; }
  .header-social__link:not(:last-child) {
    margin-right: 29px; }
  .header-social__icon {
    width: 24px;
    height: 24px; }

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 71px 0 23px; }
  @media (max-width: 767.98px) {
    .header-top {
      flex-direction: column; } }
  .header-top__logo {
    width: 210px;
    height: auto; }
    @media (max-width: 767.98px) {
      .header-top__logo {
        margin-bottom: 20px; } }
  .header-top__address {
    font-size: 14px;
    line-height: 18px; }
    @media (max-width: 1199.98px) {
      .header-top__address {
        display: none; } }
  @media (max-width: 767.98px) {
    .header-top__opendays {
      margin-bottom: 20px; } }
  .header-top__opendays p {
    display: block;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400; }
    .header-top__opendays p span {
      font-weight: 700; }
  .header-top__btn {
    border-radius: 5px;
    background-color: #842064;
    color: #fff;
    text-align: center;
    display: inline-block;
    font-weight: 700;
    font-size: 17px;
    line-height: 15px;
    padding: 19px 40px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
    transition: all 0.5s ease; }
    @media (max-width: 767.98px) {
      .header-top__btn {
        order: 1; } }
    @media (max-width: 767.98px) {
      .header-top__btn {
        margin-bottom: 20px; } }
    .header-top__btn:hover {
      background: #6d1852;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); }
  .header-top__contact {
    display: flex;
    flex-direction: column; }
    @media (max-width: 767.98px) {
      .header-top__contact {
        margin-bottom: 20px; } }
    .header-top__contact .header__contact {
      display: flex;
      align-items: center;
      font-weight: 900;
      color: #842064;
      font-size: 24px;
      transition: color 0.5s ease; }
      .header-top__contact .header__contact span {
        font-size: 16px;
        font-weight: 700;
        color: black;
        margin-right: 3px; }

.header .menu-inner {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #f9eee6;
  border: none;
  justify-content: center;
  border-radius: 3px;
  display: none;
  margin-bottom: 20px; }
  @media (max-width: 991.98px) {
    .header .menu-inner {
      display: flex; } }

.header .menu-toggle {
  display: none; }
  @media (max-width: 991.98px) {
    .header .menu-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: -15px; }
      .header .menu-toggle img {
        display: block;
        width: 30px;
        height: 25px;
        margin-right: 15px;
        margin-bottom: 3px; }
      .header .menu-toggle P {
        font-size: 32px;
        line-height: 32px;
        font-weight: 700; } }

.header .menu-open .services-menu {
  position: static !important;
  background: transparent;
  padding: 0;
  box-shadow: none; }

.header .menu-open .header-menu {
  transition: all 0.2s; }

.header .menu-open .services-menu__items {
  margin-bottom: 0 !important; }

.header .menu-open .header-menu__services {
  padding: 10px 5px; }

.header .menu-open .header-menu__list.active:after,
.header .menu-open .header-menu__list:after {
  content: '';
  left: -40px;
  width: 5px;
  height: 24px;
  background: #82276d;
  right: unset;
  top: 50%;
  transform: translateY(-50%); }

.header .menu-open .services-menu__items {
  position: relative;
  transition: all 0.5s ease; }

.header .menu-open .services-menu__title:hover:after {
  content: '';
  position: absolute;
  display: block;
  left: -40px;
  width: 5px;
  height: 24px;
  background: #82276d;
  right: unset;
  top: 50%;
  transform: translateY(-50%); }

.header .menu-open .services-menu__title {
  padding: 5px 5px 5px 20px;
  margin: 0;
  color: #000;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400; }

.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #fcdec6;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative; }
  .header-menu .close__btn {
    display: none;
    position: absolute;
    font-size: 50px;
    line-height: 28px;
    top: 130px;
    right: 20px;
    cursor: pointer; }
    @media (max-width: 991.98px) {
      .header-menu .close__btn {
        display: block; } }
  @media (max-width: 991.98px) {
    .header-menu {
      display: block;
      position: fixed;
      top: 0;
      left: -320px;
      width: 300px;
      background: #f9eee6;
      z-index: 14;
      height: 100vh;
      border: none;
      border-radius: 0;
      transition: all 0.3s;
      padding: 140px 20px 100px 40px; } }
  .header-menu .services-menu {
    display: none;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    top: 55px;
    left: 15px;
    background: #fff;
    z-index: 12;
    padding: 40px 30px;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.3);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    max-width: 330px;
    width: 100%; }
    .header-menu .services-menu__wrapper {
      max-width: 270px;
      width: 100%; }
    .header-menu .services-menu__items:not(:last-child) {
      margin-bottom: 15px; }
    .header-menu .services-menu__title {
      font-size: 18px;
      font-weight: 400;
      color: #842064;
      background: #f9eee6;
      display: inline-block;
      border-radius: 3px;
      padding: 10px 21px 10px 9px;
      text-decoration: underline;
      margin-bottom: 5px; }
      .header-menu .services-menu__title:hover {
        font-weight: 700; }
    .header-menu .services-menu__list {
      display: flex;
      align-items: center; }
      .header-menu .services-menu__list:not(:last-child) {
        margin-bottom: 5px; }
    .header-menu .services-menu__link {
      font-size: 14px;
      line-height: 19px;
      color: #000;
      font-weight: 400; }
      .header-menu .services-menu__link:hover {
        font-weight: 700; }
    .header-menu .services-menu__img {
      width: 5px;
      margin-right: 5px; }
  .header-menu__services {
    background: #f9eee6;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    padding: 12px 9px 11px;
    width: 121px;
    cursor: pointer; }
    .header-menu__services .header-services__link {
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      color: #000; }
      @media (max-width: 991.98px) {
        .header-menu__services .header-services__link {
          font-size: 20px; } }
    .header-menu__services .header-services__toggle {
      width: 16px;
      height: auto; }
      @media (max-width: 991.98px) {
        .header-menu__services .header-services__toggle {
          display: none; } }
    .header-menu__services .header-services__chevron {
      width: 20px;
      height: auto;
      margin-top: 3px; }
      @media (max-width: 991.98px) {
        .header-menu__services .header-services__chevron {
          width: 21px; } }
      @media (max-width: 991.98px) {
        .header-menu__services .header-services__chevron {
          margin-top: 2px; } }
  .header-menu__list {
    position: relative; }
  .header-menu__list:after, .header-menu__list.active:after {
    content: url("../img/icons/menu-arrow.svg");
    display: block;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: -22px;
    width: 26px;
    height: auto;
    opacity: 1; }
  .header-menu__list:after {
    opacity: 0;
    transition: opacity 0.5s ease; }
  .header-menu__list:hover:after {
    opacity: 1; }
  .header-menu__link {
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: #000;
    padding: 5px 10px; }
    @media (max-width: 1199.98px) {
      .header-menu__link {
        padding: 5px; } }
    @media (max-width: 991.98px) {
      .header-menu__link {
        font-size: 20px; } }
    @media (max-width: 991.98px) {
      .header-menu__link {
        padding: 10px 5px; } }

/* Slider */
.slider {
  margin-bottom: 81px; }
  @media (max-width: 575.98px) {
    .slider {
      margin-bottom: 60px; } }
  .slider-wrapper {
    position: relative; }
    .slider-wrapper .slick-slide img {
      width: 100%; }
    .slider-wrapper .slick-arrow {
      width: 58px;
      height: 58px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer; }
      @media (max-width: 991.98px) {
        .slider-wrapper .slick-arrow {
          top: unset;
          bottom: -80px !important;
          width: 45px;
          height: 45px; } }
    .slider-wrapper .next-arrow {
      right: -29px;
      z-index: 10;
      transform: rotate(180deg) translateY(50%);
      top: 50%; }
      @media (max-width: 991.98px) {
        .slider-wrapper .next-arrow {
          right: 40%; } }
      @media (max-width: 767.98px) {
        .slider-wrapper .next-arrow {
          right: 35%; } }
    .slider-wrapper .prev-arrow {
      left: -29px;
      z-index: 10; }
      @media (max-width: 991.98px) {
        .slider-wrapper .prev-arrow {
          left: 40%; } }
      @media (max-width: 767.98px) {
        .slider-wrapper .prev-arrow {
          left: 35%; } }

/* About */
.about {
  margin-bottom: 90px; }
  @media (max-width: 767.98px) {
    .about {
      padding-top: 70px; } }
  .about-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    padding: 0 25px; }
    @media (max-width: 767.98px) {
      .about-wrapper {
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding: 0; } }
    @media (max-width: 575.98px) {
      .about-wrapper {
        justify-content: space-between; } }
  .about-items {
    max-width: 205px;
    width: 100%;
    padding: 0 15px; }
    @media (max-width: 767.98px) {
      .about-items {
        margin-bottom: 20px; } }
    @media (max-width: 575.98px) {
      .about-items {
        max-width: 180px;
        padding: 0 10px; } }
    .about-items__title h2 {
      font-size: 21px;
      line-height: 19px;
      color: #842064;
      font-weight: 700;
      margin-bottom: 21px; }
    .about-items__subtitle {
      font-size: 16px;
      line-height: 18px; }
      @media (max-width: 767.98px) {
        .about-items__subtitle {
          font-size: 15px; } }

/* Services */
.services .subtitle {
  margin-bottom: 220px; }
  @media (max-width: 575.98px) {
    .services .subtitle br {
      display: none; } }

.services-inner {
  margin-bottom: 210px;
  height: 100%;
  background: #fdecdf; }

.services-card-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%; }
  @media (max-width: 991.98px) {
    .services-card-top {
      justify-content: center; } }
  .services-card-top .services-card {
    margin-top: -90px;
    margin-bottom: 100px; }
    @media (max-width: 991.98px) {
      .services-card-top .services-card {
        margin-left: 22px;
        margin-right: 22px; } }
    @media (max-width: 575.98px) {
      .services-card-top .services-card {
        margin-left: 10px;
        margin-right: 10px; } }
    @media (max-width: 575.98px) {
      .services-card-top .services-card:nth-child(2) {
        display: none; } }
    @media (max-width: 991.98px) {
      .services-card-top .services-card:nth-child(3) {
        display: none; } }
    @media (max-width: 1199.98px) {
      .services-card-top .services-card:nth-child(4) {
        display: none; } }

.services-card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%; }
  @media (max-width: 991.98px) {
    .services-card-bottom {
      justify-content: center; } }
  .services-card-bottom .services-card {
    margin-bottom: -90px; }
    @media (max-width: 991.98px) {
      .services-card-bottom .services-card {
        margin-left: 22px;
        margin-right: 22px; } }
    @media (max-width: 991.98px) {
      .services-card-bottom .services-card:nth-child(1) {
        display: none; } }
    @media (max-width: 575.98px) {
      .services-card-bottom .services-card:nth-child(2) {
        margin-bottom: 100px; } }
    @media (max-width: 1199.98px) {
      .services-card-bottom .services-card:nth-child(3) {
        display: none; } }

.services-card-middle {
  display: none; }
  @media (max-width: 991.98px) {
    .services-card-middle {
      display: flex;
      justify-content: center;
      flex-wrap: wrap; } }
  @media (max-width: 991.98px) {
    .services-card-middle .services-card {
      margin-left: 22px;
      margin-right: 22px;
      margin-bottom: 100px; }
      .services-card-middle .services-card:nth-child(1) {
        display: none; }
      .services-card-middle .services-card:nth-child(6) {
        display: none; } }
  @media (max-width: 575.98px) {
    .services-card-middle .services-card {
      margin-left: 22px;
      margin-right: 22px; }
      .services-card-middle .services-card:nth-child(1) {
        display: block; }
      .services-card-middle .services-card:nth-child(5) {
        display: block; }
      .services-card-middle .services-card:nth-child(6) {
        display: none; } }

.services-card {
  max-width: 263px;
  width: 100%;
  min-height: 370px;
  background: #fff;
  position: relative;
  padding: 140px 20px 15px;
  border: 1px solid #fcdec6;
  border-radius: 10px;
  transition: all 0.5s ease; }
  .services-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }
  .services-card__img {
    position: absolute;
    left: 50%;
    top: -72px;
    transform: translateX(-50%);
    display: block;
    width: 187px;
    height: 187px; }
  .services-card__title h3 {
    font-size: 20px;
    line-height: 24px;
    color: #842064;
    margin-bottom: 12px; }
  .services-card__subtitle {
    font-size: 12px;
    line-height: 15px; }
  .services-card__btn {
    display: inline-block;
    width: 229px;
    background: #fff;
    border: 1px solid #fcdec6;
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    color: #842064;
    border-radius: 30px;
    padding: 10px 0 12px;
    font-weight: 700;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    transition: background 0.5s ease; }
    .services-card__btn:hover {
      background: #fcdec6; }

/* Our Stuff */
.ourstuff {
  margin-bottom: 115px; }
  .ourstuff-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 1199.98px) {
      .ourstuff-wrapper {
        flex-direction: column;
        align-items: center; } }
  .ourstuff-content {
    max-width: 670px;
    width: 100%;
    background: #fdecdf;
    border-radius: 10px;
    padding: 60px 35px 56px;
    position: absolute;
    top: 0;
    left: 0;
    height: 445px; }
    @media (max-width: 1199.98px) {
      .ourstuff-content {
        position: static;
        max-width: 695px;
        text-align: center;
        height: auto; } }
    @media (max-width: 575.98px) {
      .ourstuff-content {
        padding: 30px 20px 35px; } }
    .ourstuff-content__title h2 {
      font-size: 36px;
      line-height: 31px;
      margin-bottom: 32px; }
    .ourstuff-content__text {
      width: 385px;
      font-size: 16px;
      line-height: 18px; }
      @media (max-width: 1199.98px) {
        .ourstuff-content__text {
          width: 100%; } }
    .ourstuff-content__btn {
      display: inline-flex;
      border: 1px solid #842064;
      border-radius: 3px;
      text-align: center;
      padding: 17px 40px;
      font-size: 18px;
      line-height: 19px;
      color: #842064;
      font-weight: 700;
      transition: all 0.5s ease; }
      @media (max-width: 1199.98px) {
        .ourstuff-content__btn {
          margin-bottom: 120px; } }
      @media (max-width: 767.98px) {
        .ourstuff-content__btn {
          display: none; } }
      .ourstuff-content__btn:hover {
        background: #842064;
        color: #fff; }
    .ourstuff-content__btn2 {
      display: none; }
      @media (max-width: 767.98px) {
        .ourstuff-content__btn2 {
          display: block;
          margin-top: 100px;
          margin-bottom: 0; } }
      @media (max-width: 575.98px) {
        .ourstuff-content__btn2 {
          padding: 15px 25px; } }
  .ourstuff .slick-slider {
    max-width: 575px;
    width: 100%; }
  .ourstuff .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 58px;
    height: 58px;
    cursor: pointer; }
    @media (max-width: 767.98px) {
      .ourstuff .slick-arrow {
        top: unset;
        bottom: -80px;
        width: 45px;
        height: 45px; } }
  .ourstuff .prev-arrow {
    left: -60px;
    z-index: 10; }
    @media (max-width: 767.98px) {
      .ourstuff .prev-arrow {
        left: 40%; } }
    @media (max-width: 575.98px) {
      .ourstuff .prev-arrow {
        left: 35%; } }
  .ourstuff .next-arrow {
    transform: translateY(-50%) rotate(180deg);
    right: -60px;
    z-index: 10; }
    @media (max-width: 767.98px) {
      .ourstuff .next-arrow {
        right: 40%; } }
    @media (max-width: 575.98px) {
      .ourstuff .next-arrow {
        right: 35%; } }
  .ourstuff-slider {
    margin-right: 46px;
    width: 100%; }
    @media (max-width: 1199.98px) {
      .ourstuff-slider {
        margin-right: 0;
        margin-top: -150px; } }
    @media (max-width: 767.98px) {
      .ourstuff-slider {
        margin-top: -80px; } }
    .ourstuff-slider__items {
      max-width: 555px;
      width: 100%;
      height: 366px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      overflow: hidden;
      display: flex;
      background: #fff;
      margin: 40px 12px 12px; }
      @media (max-width: 575.98px) {
        .ourstuff-slider__items {
          margin-left: 0;
          height: 260px;
          max-width: 375px;
          margin: 40px auto 12px; } }
      .ourstuff-slider__items .stuff-img img {
        max-width: 275px;
        width: 100%;
        height: 100%;
        display: block; }
        @media (max-width: 575.98px) {
          .ourstuff-slider__items .stuff-img img {
            max-width: 270px; } }
      .ourstuff-slider__items .stuff-info {
        padding: 35px 27px 25px 20px;
        background: #fff;
        max-width: 300px;
        min-width: 200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        @media (max-width: 575.98px) {
          .ourstuff-slider__items .stuff-info {
            padding: 15px 15px 15px 10px;
            height: 100%;
            max-width: 200px; } }
        .ourstuff-slider__items .stuff-info__name h2 {
          font-size: 26px;
          line-height: 28px;
          font-weight: 400;
          margin-bottom: 11px; }
          @media (max-width: 575.98px) {
            .ourstuff-slider__items .stuff-info__name h2 {
              font-size: 20px; } }
          @media (max-width: 575.98px) {
            .ourstuff-slider__items .stuff-info__name h2 {
              line-height: 21px; } }
          .ourstuff-slider__items .stuff-info__name h2 span {
            display: block;
            font-weight: 700; }
        .ourstuff-slider__items .stuff-info__text {
          font-size: 14px;
          line-height: 18px; }
        .ourstuff-slider__items .stuff-info__btn {
          max-width: 236px;
          width: 100%;
          background: #842064;
          color: #fff;
          display: flex;
          align-items: center;
          text-align: center;
          padding: 12px 22px 12px;
          margin-top: 15px;
          font-size: 16px;
          border-radius: 3px;
          transition: all 0.5s ease; }
          @media (max-width: 575.98px) {
            .ourstuff-slider__items .stuff-info__btn {
              font-size: 14px;
              max-width: 170px;
              padding: 3px 10px; } }
          .ourstuff-slider__items .stuff-info__btn:hover {
            background: #6d1852;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); }
          .ourstuff-slider__items .stuff-info__btn img {
            width: 20px;
            margin-right: 10px; }
      .ourstuff-slider__items .stuff-contact__item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        margin-bottom: 7px; }
        @media (max-width: 575.98px) {
          .ourstuff-slider__items .stuff-contact__item {
            margin-bottom: 5px;
            font-size: 13px; } }
        .ourstuff-slider__items .stuff-contact__item:last-child {
          margin-bottom: 18px; }
        .ourstuff-slider__items .stuff-contact__item img {
          width: 16px;
          height: auto;
          margin-right: 5px; }

/* Special */
.special {
  padding-bottom: 100px; }
  .special .subtitle {
    max-width: 445px;
    margin-bottom: 48px; }
  .special-slider {
    max-width: 850px;
    width: 100%; }
    .special-slider__wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 50px; }
  .special .slick-list {
    margin: 0 -15px !important; }
  .special .slick-slide {
    margin-left: 15px !important;
    margin-right: 15px !important; }
    @media (max-width: 991.98px) {
      .special .slick-slide {
        display: flex;
        justify-content: center; } }
  .special .slick-arrow {
    width: 58px;
    height: 58px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer; }
    @media (max-width: 575.98px) {
      .special .slick-arrow {
        width: 45px;
        height: 45px; } }
  .special .prev-arrow {
    left: -90px; }
    @media (max-width: 991.98px) {
      .special .prev-arrow {
        left: 0;
        z-index: 12; } }
  .special .next-arrow {
    right: -90px;
    transform: translateY(-50%) rotate(180deg); }
    @media (max-width: 991.98px) {
      .special .next-arrow {
        right: 0; } }
  .special-card {
    width: 262px !important;
    border: 1px solid #fcdec6;
    border-radius: 10px;
    overflow: hidden;
    min-height: 505px;
    position: relative; }
    .special-card__img {
      display: block;
      width: 100%; }
    .special-card__content {
      height: 190px;
      background: #fdecdf;
      padding: 16px 18px; }
    .special-card__title h3 {
      font-size: 20px;
      line-height: 22px;
      color: #842064;
      margin-bottom: 25px; }
    .special-card__text {
      font-size: 12px;
      line-height: 15px; }
    .special-card__price {
      padding: 15px 0;
      text-align: center; }
      .special-card__price p {
        font-size: 30px;
        line-height: 26px;
        color: #842064;
        font-weight: 700;
        margin-bottom: 15px; }
      .special-card__price span {
        text-decoration: line-through;
        font-size: 18px;
        line-height: 25px; }
  .special-btn {
    font-size: 17.5px;
    line-height: 19px;
    font-weight: 700;
    color: #fff;
    background: #842064;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 236px;
    padding: 15px 20px 16px;
    border-radius: 3px;
    transition: all 0.5s ease; }
    @media (max-width: 767.98px) {
      .special-btn {
        padding-left: 10px;
        padding-right: 10px;
        width: 200px; } }
    .special-btn:hover {
      background: #6d1852;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); }

/* Result */
.result {
  position: relative;
  padding-top: 134px;
  padding-bottom: 90px; }
  .result-inner {
    background: #fdecdf;
    height: 372px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding-top: 45px;
    z-index: -1; }
  .result-slider {
    z-index: 10;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 47px; }
    .result-slider .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center; }
    .result-slider .slick-arrow {
      position: absolute;
      top: 210px;
      width: 58px;
      height: 58px;
      cursor: pointer; }
      @media (max-width: 575.98px) {
        .result-slider .slick-arrow {
          width: 45px;
          height: 45px;
          top: 215px; } }
    .result-slider .prev-arrow {
      left: -64px; }
      @media (max-width: 991.98px) {
        .result-slider .prev-arrow {
          left: -10px;
          z-index: 12; } }
    .result-slider .next-arrow {
      right: -64px;
      transform: rotate(180deg); }
      @media (max-width: 991.98px) {
        .result-slider .next-arrow {
          right: -10px; } }
  .result-card {
    max-width: 357px;
    width: 100%;
    border: 1px solid #f9eee6;
    border-radius: 10px;
    overflow: hidden;
    min-height: 424px; }
    .result-card__img {
      display: block;
      width: 100%; }
    .result-card__content {
      padding: 28px 25px 20px; }
    .result-card__title h2 {
      font-size: 20px;
      line-height: 22px;
      font-weight: 700;
      color: #842064;
      margin-bottom: 20px; }
    .result-card__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; }
    .result-card__scope {
      width: 41px;
      margin-right: 28px; }
    .result-card__text {
      font-size: 12px;
      line-height: 17px; }
  .result-btn {
    font-size: 17.5px;
    line-height: 19px;
    font-weight: 700;
    color: #fff;
    background: #842064;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 236px;
    padding: 15px 20px 16px;
    border-radius: 3px;
    transition: all 0.5s ease; }
    .result-btn:hover {
      background: #6d1852;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); }

/* Info */
.info {
  padding-bottom: 15px; }
  .info-items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media (max-width: 767.98px) {
      .info-items {
        flex-direction: column; } }
    .info-items__content {
      max-width: 750px;
      width: 100%;
      margin-bottom: 25px; }
      @media (max-width: 767.98px) {
        .info-items__content {
          max-width: 100%; } }
    .info-items__img {
      max-width: 360px;
      width: 100%;
      display: block; }
      @media (max-width: 767.98px) {
        .info-items__img {
          max-width: 100%; } }
    .info-items__title h1 {
      max-width: 520px;
      width: 100%;
      font-size: 36px;
      font-weight: 700;
      line-height: 42px;
      margin-bottom: 25px; }
      @media (max-width: 767.98px) {
        .info-items__title h1 {
          max-width: 100%; } }
    .info-items__subtitle h2 {
      margin-top: 10px;
      font-size: 27px;
      font-weight: 700;
      margin-bottom: 15px; }
    .info-items__text {
      font-size: 16px;
      line-height: 20px; }
      .info-items__text span {
        display: block;
        margin: 20px 0; }

/* Consult */
.consult {
  margin-bottom: 90px; }
  .consult-block {
    max-width: 950px;
    width: 100%;
    position: relative;
    background: #fdecdf;
    border-radius: 10px;
    padding: 55px 0 60px 97px;
    min-height: 540px; }
    @media (max-width: 991.98px) {
      .consult-block {
        padding: 30px 40px; } }
    .consult-block__img {
      position: absolute;
      bottom: 0;
      right: -190px;
      max-width: 355px;
      width: 100%; }
      @media (max-width: 1199.98px) {
        .consult-block__img {
          right: 0;
          max-width: 285px; } }
      @media (max-width: 991.98px) {
        .consult-block__img {
          display: none; } }
    .consult-block__title h2 {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 25px; }
      @media (max-width: 767.98px) {
        .consult-block__title h2 {
          text-align: center;
          z-index: 3; } }
    .consult-block__contact {
      display: flex;
      align-items: center;
      margin-bottom: 42px; }
      @media (max-width: 767.98px) {
        .consult-block__contact {
          flex-direction: column; } }
    .consult-block__phone {
      display: flex;
      align-items: center;
      font-weight: 900;
      color: #842064;
      font-size: 24px;
      transition: color 0.5s ease; }
      .consult-block__phone:not(:last-child) {
        margin-right: 40px; }
        @media (max-width: 767.98px) {
          .consult-block__phone:not(:last-child) {
            margin-right: 0;
            margin-bottom: 20px; } }
      .consult-block__phone img {
        width: 18px;
        display: inline-block;
        margin-bottom: 8px; }
      .consult-block__phone span {
        font-size: 16px;
        font-weight: 700;
        color: black;
        margin-right: 3px; }
    .consult-block__subtext {
      max-width: 535px;
      width: 100%;
      font-size: 20px;
      line-height: 28px;
      font-weight: 400; }
  .consult-form {
    max-width: 555px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px; }
    .consult-form__name, .consult-form__phone {
      max-width: 260px;
      width: 100%;
      border: 1px solid #fcdec6;
      border-radius: 5px;
      padding: 22px 19px 20px;
      font-size: 16px;
      color: #000;
      margin-bottom: 45px; }
      @media (max-width: 767.98px) {
        .consult-form__name, .consult-form__phone {
          max-width: 100%;
          z-index: 4; } }
      .consult-form__name::placeholder, .consult-form__phone::placeholder {
        font-size: 16px;
        color: #000; }
    .consult-form__phone::-webkit-outer-spin-button, .consult-form__phone::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .consult-form__btn {
      max-width: 260px;
      width: 100%;
      border-radius: 5px;
      background-color: #842064;
      color: #fff;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      font-weight: 700;
      font-size: 17px;
      line-height: 15px;
      padding: 22px 38px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
      border: none;
      transition: all 0.5s ease; }
      @media (max-width: 767.98px) {
        .consult-form__btn {
          margin: 0 auto 30px; } }
      .consult-form__btn:hover {
        background: #6d1852;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); }
    .consult-form__checkbox {
      max-width: 260px;
      width: 100%;
      display: flex;
      align-items: flex-start; }
      @media (max-width: 767.98px) {
        .consult-form__checkbox {
          margin: 0 auto; } }
      .consult-form__checkbox .checkmark {
        position: relative;
        width: 22px;
        height: 22px;
        background: transparent;
        border: 3px solid #841f64;
        border-radius: 3px;
        margin-right: 11px;
        cursor: pointer; }
        .consult-form__checkbox .checkmark .checkbox-img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: none; }
        .consult-form__checkbox .checkmark .checkbox-img.active {
          display: block; }
      .consult-form__checkbox .checkmark.active {
        border: none; }
      .consult-form__checkbox p {
        width: 162px;
        font-size: 12px;
        line-height: 15px; }
        .consult-form__checkbox p a {
          color: #000;
          text-decoration: underline; }

/* Gallery */
.gallery {
  margin-bottom: 90px; }
  .gallery .title {
    margin-bottom: 40px; }
  .gallery-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px; }
    @media (max-width: 991.98px) {
      .gallery-wrapper {
        justify-content: space-evenly; } }
    @media (max-width: 767.98px) {
      .gallery-wrapper {
        justify-content: center; } }
  .gallery-items {
    max-width: 360px;
    position: relative;
    margin-bottom: 30px; }
    @media (max-width: 1199.98px) {
      .gallery-items {
        max-width: 100%; } }
    .gallery-items:hover .gallery-items__link {
      opacity: 1;
      z-index: 12; }
    .gallery-items__img {
      display: block;
      width: 100%;
      height: 100%; }
    .gallery-items__link {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 96px;
      height: 96px;
      background-color: rgba(253, 236, 223, 0.85);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;
      opacity: 0;
      z-index: -1; }
      .gallery-items__link img {
        width: 45px; }
  .gallery-btn {
    width: 235px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    color: #842064;
    border: 1px solid #842064;
    border-radius: 3px;
    padding: 16px 10px;
    font-size: 18px;
    line-height: 19px;
    font-weight: 700;
    transition: all 0.5s ease; }
    .gallery-btn:hover {
      background: #842064;
      color: #fff; }

/* Address */
.address .title {
  margin-bottom: 50px; }

.address iframe {
  display: block; }
  @media (max-width: 767.98px) {
    .address iframe {
      order: 1;
      height: 300px; } }

.address .container-parent {
  width: 100%;
  position: relative; }
  .address .container-parent .address-block-2 {
    display: none; }
    @media (max-width: 767.98px) {
      .address .container-parent .address-block-2 {
        display: block !important;
        position: static;
        max-width: 100%;
        border: none;
        min-height: auto; } }
    .address .container-parent .address-block-2 .address-logo {
      margin-bottom: 20px; }
    .address .container-parent .address-block-2 .address-tel {
      margin-bottom: 20px; }
  .address .container-parent .address-block {
    position: absolute;
    top: -15px;
    left: 0;
    max-width: 360px;
    width: 100%;
    min-height: 580px;
    background: #fff;
    z-index: 10;
    border: 1px solid #fcdec6;
    border-radius: 5px;
    padding: 40px 50px 25px;
    text-align: center; }
    @media (max-width: 767.98px) {
      .address .container-parent .address-block {
        display: none; } }
  .address .container-parent .address-logo {
    width: 220px;
    margin-bottom: 65px; }
  .address .container-parent .address-tel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 45px; }
    .address .container-parent .address-tel__img {
      width: 30px;
      margin-right: 10px; }
    .address .container-parent .address-tel__contact {
      display: flex;
      flex-direction: column; }
    .address .container-parent .address-tel__phone {
      font-weight: 900;
      color: #842064;
      font-size: 24px;
      transition: color 0.5s ease; }
      .address .container-parent .address-tel__phone span {
        font-size: 16px;
        font-weight: 700;
        color: black;
        margin-right: 3px; }
  .address .container-parent .address-info__title {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 7px; }
  .address .container-parent .address-info__text {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 30px; }
    .address .container-parent .address-info__text span {
      font-weight: 700; }
  .address .container-parent .address-social {
    margin-top: 10px; }
    .address .container-parent .address-social__title {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 15px; }
    .address .container-parent .address-social__items {
      display: flex;
      align-items: center;
      justify-content: center; }
      .address .container-parent .address-social__items a {
        width: 26px;
        height: 26px;
        border-radius: 50%; }
        .address .container-parent .address-social__items a:not(:last-child) {
          margin-right: 15px; }
        .address .container-parent .address-social__items a img {
          width: 100%;
          height: 100%; }

/* Footer */
.footer {
  background: #fef8f3; }
  .footer-wrapper {
    padding: 90px 0 35px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative; }
    @media (max-width: 991.98px) {
      .footer-wrapper {
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 40px;
        text-align: center; } }
  .footer-services {
    max-width: 225px;
    width: 100%; }
    @media (max-width: 991.98px) {
      .footer-services {
        margin-bottom: 30px; } }
    .footer-services__title h3 {
      font-size: 19px;
      line-height: 31px;
      font-weight: 700;
      margin-bottom: 13px; }
    .footer-services__menu li:not(:last-child) {
      margin-bottom: 10px; }
    .footer-services__menu li a {
      color: #000;
      font-size: 17px;
      font-weight: 400; }
      .footer-services__menu li a:hover {
        font-weight: 700; }
  @media (max-width: 991.98px) {
    .footer-promotions {
      max-width: 265px;
      width: 100%;
      margin-bottom: 30px; } }
  .footer-promotions__menu li:not(:last-child) {
    margin-bottom: 9px; }
  .footer-promotions__menu li a {
    font-weight: 700;
    color: #000;
    font-size: 19px; }
    .footer-promotions__menu li a:hover {
      text-decoration: underline; }
  @media (max-width: 991.98px) {
    .footer-bank {
      margin-bottom: 30px; } }
  .footer-bank__text {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 20px; }
  .footer-bank__info p {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 12px; }
  .footer-bank__card {
    display: flex;
    margin-bottom: 10px; }
    @media (max-width: 991.98px) {
      .footer-bank__card {
        justify-content: center; } }
    .footer-bank__card img {
      width: 39px;
      display: block; }
      .footer-bank__card img:not(:last-child) {
        margin-right: 22px; }
  .footer-bank__subtext {
    display: block;
    color: #000;
    text-decoration: underline;
    font-size: 13px; }
    .footer-bank__subtext:not(:last-child) {
      margin-bottom: 5px; }
    .footer-bank__subtext:hover {
      text-decoration: underline; }
  .footer-info {
    max-width: 265px;
    width: 100%; }
    @media (max-width: 991.98px) {
      .footer-info {
        margin-bottom: 30px; } }
    .footer-info p {
      font-size: 12.5px;
      line-height: 15.5px; }
      .footer-info p:not(:last-child) {
        margin-bottom: 20px; }
  .footer-copy {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: center;
    width: 260px;
    border-top-right-radius: 21px;
    border-top-left-radius: 21px;
    background: #fcdec6;
    padding: 10px 5px 6px; }
    @media (max-width: 991.98px) {
      .footer-copy {
        right: 50%;
        transform: translateX(50%); } }
    .footer-copy p {
      font-size: 13px; }
      .footer-copy p span {
        font-weight: 700; }

/* Page Promotions */
.promotions {
  padding-top: 25px;
  padding-bottom: 70px; }
  .promotions .title h2 {
    text-align: left !important;
    margin-bottom: 45px; }
  .promotions-items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; }
    @media (max-width: 1199.98px) {
      .promotions-items {
        justify-content: center; } }
    .promotions-items .special-card {
      margin-bottom: 30px; }
      @media (max-width: 1199.98px) {
        .promotions-items .special-card {
          margin: 0 15px 30px; } }
    .promotions-items .spacial-card-last {
      border: none;
      min-height: 0; }

.promotions-info {
  padding-bottom: 100px; }
  .promotions-info .info-items__content {
    max-width: 100%;
    margin-bottom: 50px; }
  .promotions-info .info-items__title h1 {
    max-width: 100%; }
  .promotions-info .info-items__text:not(:last-child) {
    margin-bottom: 20px; }
  .promotions-info .info-items .text__wrapper {
    display: flex;
    align-items: center; }
    .promotions-info .info-items .text__wrapper img {
      width: 15px;
      margin-left: 7px; }

/* Page Prices */
.prices {
  padding: 35px 0 125px;
  position: relative !important; }
  @media (max-width: 1199.98px) {
    .prices {
      padding-bottom: 60px; } }
  .prices .title h2 {
    text-align: left;
    margin-bottom: 50px; }
  .prices-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media (max-width: 1199.98px) {
      .prices-wrapper {
        flex-direction: column; } }
  .prices .fixed {
    position: sticky;
    top: 20px;
    left: 0; }
  .prices aside {
    max-width: 263px;
    width: 100%;
    border: 1px solid #fcdec6;
    min-height: 460px;
    background: #fff;
    z-index: 9; }
    @media (max-width: 1199.98px) {
      .prices aside {
        display: none; } }
  .prices-nav__title {
    max-width: 250px;
    width: 100%;
    background: #fef5ef;
    text-align: center;
    padding: 17px 0 15px;
    font-size: 19px;
    font-weight: 700;
    margin: 8px 6px; }
  .prices-nav__list {
    width: 100%; }
    .prices-nav__list span {
      width: 245px;
      display: block;
      margin: 0 auto;
      border-bottom: 1px solid #fdecdf; }
  .prices-nav__link {
    display: flex;
    align-items: center;
    font-size: 17px;
    line-height: 20px;
    color: #000;
    font-weight: 400;
    padding: 6px 6px 6px 0;
    margin: 10px 0;
    transition: all 0.5s ease; }
    .prices-nav__link img {
      width: 13px;
      height: 22px;
      transform: rotate(90deg);
      margin-right: 12px;
      margin-left: 6px; }
    .prices-nav__link:hover, .prices-nav__link.open {
      background: #fef5ef; }
  .prices-inner__wrapper {
    max-width: 845px;
    width: 100%; }
    @media (max-width: 1199.98px) {
      .prices-inner__wrapper {
        max-width: 100%; } }
  @media (max-width: 1199.98px) {
    .prices .blog-wrapper {
      display: none; } }
  .prices-blog {
    transition: all 0.5s; }
    .prices-blog__nav {
      display: none;
      background: #fcdec6;
      width: 100%;
      justify-content: space-between;
      padding: 18px 20px 18px 18px;
      margin-bottom: 10px;
      cursor: pointer; }
      @media (max-width: 1199.98px) {
        .prices-blog__nav {
          display: flex; } }
      .prices-blog__nav-title {
        font-size: 16px;
        line-height: 17px;
        color: #000; }
        @media (max-width: 767.98px) {
          .prices-blog__nav-title {
            max-width: 270px;
            width: 100%;
            margin-right: 10px; } }
        .prices-blog__nav-title span {
          display: block;
          font-size: 14px;
          line-height: 16px; }
      .prices-blog__nav-img {
        width: 15px; }
    .prices-blog__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 19px;
      background: #fef5ef;
      margin-bottom: 10px; }
      @media (max-width: 767.98px) {
        .prices-blog__items {
          flex-direction: column;
          align-items: flex-start; } }
    .prices-blog__name {
      font-size: 17px;
      line-height: 20px;
      max-width: 545px; }
      @media (max-width: 767.98px) {
        .prices-blog__name {
          font-size: 16px;
          line-height: 18px; } }
    .prices-blog__price {
      font-size: 25px;
      line-height: 20px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-left: 10px; }
      @media (max-width: 1199.98px) {
        .prices-blog__price {
          font-weight: 700; } }
      @media (max-width: 767.98px) {
        .prices-blog__price {
          margin-top: 10px;
          font-size: 16px;
          line-height: 18px; } }
      .prices-blog__price span {
        font-size: 15px !important;
        line-height: 20px;
        margin-left: 17px;
        text-decoration: line-through; }
        @media (max-width: 1199.98px) {
          .prices-blog__price span {
            font-weight: 400; } }
    .prices-blog__promotion {
      width: 85px;
      text-align: center;
      color: #fff;
      background: #842064;
      border-radius: 3px;
      padding: 5px;
      font-size: 17px;
      line-height: 20px;
      margin-right: 24px;
      font-weight: 400; }
    .prices-blog__title h2 {
      font-size: 28px;
      line-height: 31px;
      font-weight: 700;
      margin: 65px 0 15px; }
      @media (max-width: 1199.98px) {
        .prices-blog__title h2 {
          display: none; } }
      .prices-blog__title h2 span {
        display: block;
        font-size: 17px;
        line-height: 20px; }

/* Prices Info */
.prices-info {
  padding-bottom: 90px; }
  .prices-info .text__bold {
    font-weight: 700; }

/* OurStuff Page */
.stuffpage {
  padding: 40px 0 45px; }
  .stuffpage .title h2 {
    text-align: left;
    margin-bottom: 45px; }
  .stuffpage-blog {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 1199.98px) {
      .stuffpage-blog {
        justify-content: center; } }
    .stuffpage-blog .ourstuff-slider__items {
      margin: 0 0 30px 0;
      transition: all 0.5s ease; }
      .stuffpage-blog .ourstuff-slider__items:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.4); }
      .stuffpage-blog .ourstuff-slider__items .stuff-info__btn {
        background: #fff;
        color: #842064;
        font-weight: 700;
        border: 1px solid #842064; }
        .stuffpage-blog .ourstuff-slider__items .stuff-info__btn svg {
          width: 20px;
          fill: #842064;
          margin-right: 8px;
          margin-bottom: 2px;
          transition: all 0.5s ease; }
        .stuffpage-blog .ourstuff-slider__items .stuff-info__btn:hover {
          background: #842064;
          color: #fff;
          box-shadow: none; }
          .stuffpage-blog .ourstuff-slider__items .stuff-info__btn:hover svg {
            fill: #fff; }

.stuffpage-info {
  padding-bottom: 60px; }
  .stuffpage-info .info-items__text:not(:last-child) {
    margin-bottom: 20px; }

/* Reviews Page */
.reviews-top {
  padding: 30px 0 75px; }
  .reviews-top__box {
    width: 100%;
    border-radius: 10px;
    background-color: #fdecdf;
    padding: 40px 95px 35px 96px; }
    @media (max-width: 767.98px) {
      .reviews-top__box {
        padding: 30px 40px; } }
  .reviews-top__title h2 {
    font-size: 36px;
    color: #000;
    font-weight: 700;
    line-height: 31px;
    margin-bottom: 25px; }
  .reviews-top__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    @media (max-width: 991.98px) {
      .reviews-top__content {
        flex-direction: column; } }
  .reviews-top__text {
    max-width: 660px;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    margin-right: 15px; }
    @media (max-width: 767.98px) {
      .reviews-top__text {
        margin-right: 0; } }
  .reviews-top__btn {
    border-radius: 5px;
    border: 1px solid #842064;
    max-width: 260px;
    width: 100%;
    padding: 20px 10px 17px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #842064;
    font-weight: 700;
    transition: all 0.5s ease; }
    @media (max-width: 991.98px) {
      .reviews-top__btn {
        margin-top: 30px; } }
    .reviews-top__btn svg {
      width: 23px;
      margin-right: 13px;
      margin-bottom: 2px;
      transition: all 0.5s ease;
      fill: #842064; }
    .reviews-top__btn:hover {
      background: #842064;
      color: #fff; }
    .reviews-top__btn:hover svg {
      fill: #fff; }

/* Reviews */
.reviews {
  padding-bottom: 90px; }
  .reviews-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px; }
  .reviews-img {
    width: 58px;
    display: block;
    margin-right: 37px; }
    @media (max-width: 575.98px) {
      .reviews-img {
        width: 40px;
        margin-right: 20px; } }
  .reviews-content {
    width: 100%; }
    .reviews-content-two {
      max-width: 100%; }
    .reviews-content__name h3 {
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 12px;
      font-weight: 700; }
    .reviews-content__subtext {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px; }
    .reviews-content__date {
      display: flex;
      align-items: center;
      margin-bottom: 18px; }
      .reviews-content__date img {
        width: 25px;
        margin-bottom: 2px;
        margin-right: 16px; }
      .reviews-content__date p {
        font-size: 16px;
        font-weight: 700; }
    .reviews-content__text {
      font-size: 16px;
      line-height: 20px; }
  .reviews-video iframe {
    display: block;
    padding-bottom: 60px;
    border-bottom: 3px solid #fdecdf;
    border-radius: 1px;
    margin-bottom: 59px; }
    @media (max-width: 991.98px) {
      .reviews-video iframe {
        height: 600px; } }
    @media (max-width: 575.98px) {
      .reviews-video iframe {
        height: 400px; } }
  .reviews-wrapper__inner {
    display: flex;
    align-items: flex-start; }
  .reviews-wrapper-two {
    padding-bottom: 60px;
    border-bottom: 3px solid #fdecdf;
    border-radius: 1px;
    margin-bottom: 60px; }
    @media (max-width: 991.98px) {
      .reviews-wrapper-two {
        flex-direction: column; } }
    .reviews-wrapper-two .reviews-picture {
      max-width: 358px;
      width: 100%;
      display: block; }
      @media (max-width: 991.98px) {
        .reviews-wrapper-two .reviews-picture {
          max-width: 500px;
          margin: 30px auto 0; } }
   

/* Main Gallery Page */
.main-gallery {
  padding: 35px 0 160px; }
  .main-gallery__header h2 {
    font-size: 36px;
    line-height: 31px;
    margin-bottom: 20px; }
  .main-gallery__text {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 50px; }
  .main-gallery__img {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 991.98px) {
      .main-gallery__img {
        flex-direction: column;
        align-items: center; } }
  .main-gallery__links {
    max-width: 555px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease; }
    .main-gallery__links:first-child {
      margin-right: 15px; }
      @media (max-width: 991.98px) {
        .main-gallery__links:first-child {
          margin-right: 0;
          margin-bottom: 30px; } }
    .main-gallery__links:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); }
    .main-gallery__links img {
      display: block;
      width: 100%;
      height: 100%; }
  .main-gallery__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; }
    .main-gallery__overlay img {
      width: 59px;
      height: 51px;
      margin-bottom: 20px;
      display: block; }
    .main-gallery__overlay p {
      color: #fff;
      font-size: 30px;
      line-height: 31px; }

/* Gallery Hospital */
.gallery-hospital {
  padding-top: 40px;
  padding-bottom: 130px;
  margin-bottom: 0; }
  .gallery-hospital .title {
    margin-bottom: 45px;
    display: flex;
    align-items: center; }
    @media (max-width: 767.98px) {
      .gallery-hospital .title {
        flex-direction: column; } }
    .gallery-hospital .title h2 {
      text-align: left;
      margin-bottom: 0;
      margin-right: 30px; }
      @media (max-width: 767.98px) {
        .gallery-hospital .title h2 {
          margin-right: 0;
          margin-bottom: 30px; } }
    .gallery-hospital .title a {
      display: flex;
      align-items: center;
      color: #842064;
      font-weight: 700;
      font-size: 18px;
      margin-top: 3px; }
      .gallery-hospital .title a img {
        margin-bottom: 3px;
        width: 14px;
        margin-right: 10px; }
  .gallery-hospital .gallery-btn {
    width: 290px;
    display: flex;
    text-align: left;
    align-items: center; }
    .gallery-hospital .gallery-btn:hover svg {
      fill: #fff; }
    .gallery-hospital .gallery-btn svg {
      width: 14px;
      margin-right: 10px;
      fill: #841f64;
      transition: all 0.5s ease;
      margin-bottom: 1px; }

.image-comparison {
  position: relative;
  display: inline-block;
  width: 355px;
  overflow: hidden; }

.image-comparison__before {
  position: absolute;
  width: 120px;
  overflow: hidden; }

.image-comparison__image {
  display: block; }

.image-comparison__slider {
  padding: 0;
  position: absolute;
  top: 50%;
  left: 120px;
  transform: translateY(-50%);
  cursor: e-resize;
  outline: none;
  opacity: 0.5;
  display: flex;
  background: none;
  border: none; }
  .image-comparison__slider img {
    position: absolute;
    top: -6px;
    width: 15px;
    z-index: 5;
    background: none; }
  .image-comparison__slider .left-chevron {
    left: -18px; }
  .image-comparison__slider .right-chevron {
    left: 3px; }

.image-comparison__slider:before {
  content: "";
  position: absolute;
  top: calc(50% - 23px);
  left: -25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff; }

.image-comparison__slider:hover,
.image-comparison__slider:focus,
.image-comparison__slider:hover::before,
.image-comparison__slider:focus::before {
  background-color: #ccc; }

/* Work Page */
.work-result {
  padding: 40px 0 100px; }
  .work-result .title h2 {
    margin-bottom: 50px;
    text-align: left; }
  .work-result__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 1199.98px) {
      .work-result__wrapper {
        justify-content: space-evenly; } }
    .work-result__wrapper .result-card {
      margin: 0 5px 30px; }
      @media (max-width: 575.98px) {
        .work-result__wrapper .result-card {
          margin-right: 0;
          margin-left: 0; } }
  .work-result .img-card {
    position: relative;
    width: 100%;
    max-height: 237px;
    height: 100%; }
    .work-result .img-card__before-img {
      display: block;
      width: 100%; }
    .work-result .img-card__after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      transition: all 0.5s ease; }
      .work-result .img-card__after-img {
        display: block;
        width: 100%; }

/* About Page */
.about-page {
  padding: 40px 0 90px; }
  .about-page__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media (max-width: 767.98px) {
      .about-page__wrapper {
        flex-direction: column;
        align-items: center; } }
  .about-page__content {
    max-width: 755px;
    width: 100%;
    margin-right: 30px; }
    @media (max-width: 767.98px) {
      .about-page__content {
        margin-right: 0; } }
    .about-page__content .title h2 {
      text-align: left;
      line-height: 48px;
      margin-bottom: 30px; }
  .about-page__text {
    font-size: 16px;
    line-height: 20px; }
    .about-page__text:not(:last-child) {
      margin-bottom: 25px; }
    .about-page__text span {
      font-weight: 600;
      font-style: italic; }
  .about-page__info {
    max-width: 360px;
    width: 100%;
    min-height: 710px;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    position: relative; }
    @media (max-width: 767.98px) {
      .about-page__info {
        margin-top: 30px; } }
    .about-page__info img {
      width: 100%; }
  .about-page__blog {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: linear-gradient(to bottom, transparent, #fdecdf, #fdecdf);
    padding-top: 130px; }
  .about-page__name h2 {
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 18px; }
  .about-page__text {
    font-size: 17px;
    line-height: 20px; }

/* License */
.license {
  padding-bottom: 90px; }
  .license .title h2 {
    text-align: left;
    margin-bottom: 35px;
    line-height: 48px; }
  .license-box {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991.98px) {
      .license-box {
        margin: 0 5px 30px;
        flex-wrap: wrap;
        justify-content: space-evenly; } }
    .license-box img {
      max-width: 355px;
      width: 100%;
      display: block; }

/* Video */
.video {
  padding-bottom: 165px; }
  @media (max-width: 767.98px) {
    .video {
      padding-bottom: 70px; } }
  .video .title h2 {
    text-align: left;
    margin-bottom: 45px; }
  .video iframe {
    display: block; }
    @media (max-width: 991.98px) {
      .video iframe {
        height: 500px; } }
    @media (max-width: 575.98px) {
      .video iframe {
        height: 400px; } }

/* Virtual */
.virtual {
  padding-bottom: 150px; }
  .virtual-box {
    padding: 10px 50px 17px 100px;
    background: #fdecdf;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      .virtual-box {
        flex-direction: column;
        padding-left: 30px; } }
    @media (max-width: 767.98px) {
      .virtual-box__img {
        margin-top: 30px; } }
    .virtual-box__img img {
      display: block; }
      @media (max-width: 575.98px) {
        .virtual-box__img img {
          width: 150px; } }
    .virtual-box__content {
      max-width: 650px;
      width: 100%; }
    .virtual-box__title h3 {
      font-size: 32px;
      line-height: 38px;
      font-weight: 700;
      margin-bottom: 25px;
      color: #000; }
    .virtual-box__text {
      font-size: 19px;
      line-height: 22px;
      color: #000; }
  .virtual-tour {
    margin-top: 70px;
    display: none; }
    @media (max-width: 991.98px) {
      .virtual-tour iframe {
        height: 500px; } }
    @media (max-width: 575.98px) {
      .virtual-tour iframe {
        height: 400px; } }

/* Page Article */
.article {
  padding: 35px 0 130px; }
  .article .title h2 {
    text-align: left;
    margin-bottom: 35px; }
  .article-blog {
    padding: 30px;
    border-radius: 10px;
    background: linear-gradient(to bottom, transparent, #fdecdf);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px; }
    @media (max-width: 767.98px) {
      .article-blog {
        flex-direction: column-reverse; } }
  .article-content {
    max-width: 715px;
    width: 100%;
    margin-right: 30px; }
    @media (max-width: 767.98px) {
      .article-content {
        margin-right: 0; } }
    .article-content__title h2 {
      font-size: 28px;
      line-height: 31px;
      margin-bottom: 20px;
      font-weight: 700; }
    .article-content__text {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 50px; }
    .article-content__btn {
      display: inline-block;
      text-align: center;
      padding: 15px 20px 14px;
      width: 236px;
      border: 1px solid #842064;
      border-radius: 5px;
      color: #842064;
      font-weight: 700;
      transition: all 0.5s ease; }
      .article-content__btn:hover {
        background: #842064;
        color: #fff; }
  .article-img {
    max-width: 330px;
    width: 100%; }
    @media (max-width: 767.98px) {
      .article-img {
        margin-bottom: 30px; } }
    .article-img img {
      width: 100%;
      display: block; }

/* Article Page */
.article-page {
  padding: 20px 0 155px; }
  .article-page__blog {
    background: #fdecdf;
    max-width: 100%;
    padding: 30px;
    border-radius: 5px; }
  .article-page__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px 60px 20px 63px;
    background: #fff;
    margin-bottom: 9px;
    border-radius: 5px;
    flex-wrap: wrap; }
    @media (max-width: 575.98px) {
      .article-page__top {
        padding: 20px 30px; } }
  .article-page__title {
    max-width: 620px;
    width: 100%;
    margin-right: 20px; }
    .article-page__title h1 {
      font-size: 42px;
      line-height: 48px;
      font-weight: 700; }
      @media (max-width: 767.98px) {
        .article-page__title h1 {
          font-size: 32px;
          line-height: 38px; } }
  .article-page__btn {
    width: 263px;
    display: flex;
    align-items: center;
    border: 1px solid #841f64;
    border-radius: 5px;
    padding: 15px 15px 16px;
    color: #841f64;
    font-weight: 700;
    transition: all 0.5s ease; }
    @media (max-width: 991.98px) {
      .article-page__btn {
        margin-top: 30px; } }
    .article-page__btn svg {
      width: 14px;
      fill: #841f64;
      transition: all 0.5s ease;
      margin-right: 10px;
      margin-bottom: 2px; }
    .article-page__btn:hover {
      background: #841f64;
      color: #fff; }
    .article-page__btn:hover svg {
      fill: #fff; }
  .article-page__btn2 {
    margin: 45px auto 0; }
  .article-page__content {
    padding: 30px 60px 20px 63px;
    background: #fff;
    border-radius: 5px; }
    @media (max-width: 575.98px) {
      .article-page__content {
        padding: 20px 30px; } }
    .article-page__content-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 30px; }
    .article-page__content-links {
      margin-left: 14px;
      margin-bottom: 40px; }
      .article-page__content-links a {
        font-size: 14px;
        color: #842064;
        line-height: 18px;
        font-weight: 400;
        display: block;
        transition: all .5s ease; }
        .article-page__content-links a.open, .article-page__content-links a:hover {
          font-weight: 700; }
    .article-page__content-text {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 45px; }
  .article-page__middle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media (max-width: 991.98px) {
      .article-page__middle {
        flex-direction: column-reverse; } }
    .article-page__middle-content {
      max-width: 560px;
      width: 100%;
      margin-bottom: 40px;
      margin-right: 15px; }
    .article-page__middle-title h2 {
      font-size: 30px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 20px; }
    .article-page__middle-text {
      font-size: 16px;
      line-height: 19px; }
      .article-page__middle-text span {
        display: block;
        margin-bottom: 20px; }
    .article-page__middle-img img {
      display: block;
      width: 360px; }
      @media (max-width: 991.98px) {
        .article-page__middle-img img {
          margin-bottom: 25px; } }
      @media (max-width: 575.98px) {
        .article-page__middle-img img {
          max-width: 360px;
          width: 100%; } }
  .article-page__info-title {
    margin: 20px 0;
    font-weight: 700; }
    .article-page__info-title span {
      font-weight: 400; }
  .article-page__info-text {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 20px; }
    .article-page__info-text .span1 {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: #842064;
      margin-right: 8px;
      display: inline-block; }
    .article-page__info-text .span2 {
      font-weight: 600; }
    .article-page__info-text .span3 {
      font-weight: 700; }

/* Contact page */
.contact {
  padding: 20px 0 100px; }
  .contact-items {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991.98px) {
      .contact-items {
        flex-direction: column;
        align-items: center; } }
    .contact-items__info .title h2 {
      text-align: left;
      margin-bottom: 30px; }
    .contact-items__text:not(:last-child) {
      margin-bottom: 25px; }
    .contact-items__text p {
      line-height: 18px;
      font-size: 14px;
      font-weight: 400; }
      .contact-items__text p span {
        font-weight: 700; }
  .contact-form {
    max-width: 555px;
    width: 100%;
    padding: 40px 100px 50px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 500px; }
    @media (max-width: 575.98px) {
      .contact-form {
        height: auto; } }
    @media (max-width: 991.98px) {
      .contact-form {
        margin-top: 30px; } }
    @media (max-width: 575.98px) {
      .contact-form {
        padding: 25px 40px; } }
    .contact-form__title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 28px; }
      .contact-form__title img {
        width: 39px;
        display: block;
        margin-right: 14px;
        margin-bottom: 6px; }
      .contact-form__title h2 {
        font-size: 30px;
        font-weight: 700; }
    .contact-form input {
      border: 1px solid #fcdec6;
      border-radius: 5px;
      width: 100%;
      font-size: 16px;
      line-height: 19px;
      padding: 20px 12px;
      color: #000;
      margin-bottom: 17px; }
      .contact-form input::placeholder {
        color: #000;
        font-size: 16px;
        line-height: 19px; }
    .contact-form__textarea {
      border: 1px solid #fcdec6;
      border-radius: 5px;
      width: 100%;
      line-height: 19px;
      padding: 20px 12px;
      color: #000;
      resize: none;
      height: 87px;
      margin-bottom: 32px;
      font: 400 16px "Lato";
      font-family: sans-serif; }
      .contact-form__textarea::placeholder {
        font: 400 16px "Lato";
        color: #000;
        font-family: sans-serif; }
    .contact-form__wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      @media (max-width: 575.98px) {
        .contact-form__wrapper {
          flex-direction: column-reverse; } }
      @media (max-width: 575.98px) {
        .contact-form__wrapper {
          align-items: center; } }
      .contact-form__wrapper .consult-form__checkbox {
        max-width: 165px; }
        @media (max-width: 575.98px) {
          .contact-form__wrapper .consult-form__checkbox {
            margin-bottom: 20px; } }
        .contact-form__wrapper .consult-form__checkbox p {
          width: 130px; }
    .contact-form__btn {
      border: none;
      background: #842064;
      width: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      height: 60px;
      font-weight: 700;
      color: #fff;
      cursor: pointer;
      border-radius: 30px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
      transition: all 0.5s ease; }
      .contact-form__btn:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.9); }
      .contact-form__btn span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 1px solid #fff;
        margin-left: 14px; }
      .contact-form__btn img {
        width: 11px; }

/* Than-you Page */
.thank-you {
  padding-top: 56px;
  width: 100%;
  text-align: center; }
  .thank-you .logo {
    width: 210px;
    margin: 0 auto 45px;
    display: block; }
  .thank-you__img {
    width: 232px;
    margin: 0 auto 55px;
    display: block; }
  .thank-you__title h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 15px; }
  .thank-you__text {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 65px; }
  .thank-you__btn {
    width: 236px;
    border: 1px solid #842064;
    border-radius: 3px;
    padding: 14px 10px;
    color: #842064;
    font-size: 18px;
    text-align: center;
    display: inline-block;
    transition: all 0.5s ease; }
    .thank-you__btn:hover {
      background: #842064;
      color: #fff; }

/* Page-404 */
.page-404 .logo {
  margin-bottom: 85px; }

.page-404__img {
  width: 135px;
  display: block;
  margin: 0 auto 65px; }

.picture {
  padding: 20px 0 55px; }
  .picture-img {
    display: block;
    width: 100%; }

.text {
  padding-bottom: 125px; }
  .text-title h2 {
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
    margin-bottom: 20px; }
    @media (max-width: 1199.98px) {
      .text-title h2 br {
        display: none; } }
  .text-content-first {
    margin-bottom: 65px; }
  .text-content__text {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400; }
    .text-content__text:not(:last-child) {
      margin-bottom: 20px; }
  .text-content__text2 {
    margin-top: 35px; }
  .text-subtitle {
    font-size: 27px;
    line-height: 42px;
    font-weight: 700;
    margin-bottom: 10px; }
  .text-blog {
    margin: 17px 0 55px;
    background: #f9eee6;
    padding: 35px 40px 28px;
    text-align: center;
    border-radius: 20px; }
    .text-blog p {
      font-size: 20px;
      line-height: 23px;
      font-weight: 400;
      max-width: 770px;
      width: 100%;
      margin: auto; }
  .text-blog2 {
    max-width: 940px;
    width: 100%;
    margin: 30px auto 43px;
    padding-left: 30px;
    padding-right: 30px; }
    .text-blog2 p {
      max-width: 790px;
      width: 100%; }
  .text-img {
    display: block;
    margin: 30px 0 50px 30px; }

.rules {
  padding-top: 40px; }
  .rules-wrapper {
    max-width: 776px;
    width: 100%;
    margin: 0 auto; }
  .rules-title h1 {
    font-size: 42px;
    line-height: 48px;
    font-weight: 700;
    margin-bottom: 25px; }
    @media (max-width: 1199.98px) {
      .rules-title h1 br {
        display: none; } }
  .rules-text {
    margin-bottom: 65px; }

.twentytwenty-horizontal .twentytwenty-handle:before,
.twentytwenty-horizontal .twentytwenty-handle:after {
  opacity: 0; }

.twentytwenty-handle {
  border: none;
  background: rgba(255, 255, 255, 0.5);
  margin-left: -19px;
  width: 40px;
  height: 40px; }

.twentytwenty-left-arrow {
  border-right: 6px solid rgba(51, 51, 51, 0.5); }

.twentytwenty-right-arrow {
  border-left: 6px solid rgba(51, 51, 51, 0.5); }
